import DataTableField from '@/components/DataTable/DataTableField'
import { startCase as _startCase } from 'lodash'

export default [
  new DataTableField('checkbox', '', 'checkbox', true, false),
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'number',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'name',
    label: 'Invoice name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center ',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'client',
      property: 'name',
    },
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'gross_rate',
    label: 'Gross rate',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'currency',
  },
  {
    key: 'net_rate',
    label: 'Net rate',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'currency',
  },
  {
    key: 'gross_subtotal',
    label: 'Gross total',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
  },
  {
    key: 'net_subtotal',
    label: 'Net total',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
  },
  {
    key: 'due_at',
    label: 'Due At',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'created_at',
    label: 'Invoice Date',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'review_status',
    label: 'Review status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },

  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    formatter: (value: string) => _startCase(value.replace(/_/g, ' ')),
  },
  {
    key: 'status_check',
    label: 'Score*',
    sortable: false,
    class: 'text-center align-middle',
    filter: false,
    show: true,
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    filter: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    thClass: 'align-middle text-center',
  },
]
