
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import Invoice from '@/models/Invoice'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import { Ref, Watch } from 'vue-property-decorator'
import { groupBy as _groupBy, clone as _clone } from 'lodash'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import WebMessage from '@/models/WebMessage'
import MediaPlan from '@/models/MediaPlan'
import Reconciliation from '@/models/Reconciliation'
import SelectOption from '@/models/interface/SelectOption'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'
import { isCampagingInDateRange } from '@/models/interface/Common'
import MediaPlanItem from '@/models/MediaPlanItem'
import InvoiceItem from '@/models/InvoiceItem'
import e from 'express'
import FaqModal from '@/components/FaqModal/FaqModal.vue'
import InvoiceBadgeStatus from './Components/InvoiceBadgeStatus.vue'
import invoice_review_table_fields from './invoice-review-table-fields'
import InvoicePrintView from './Components/InvoicePrintView.vue'
import MediaPlanView from '../Sales/MediaPlan/MediaPlanView.vue'
import MediaOceanPrintView from './Components/MediaOceanPrintView.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    FormInput,
    InvoiceBadgeStatus,
    FooterNav,
    InvoicePrintView,
    MediaPlanView,
    MediaOceanPrintView,
    SelectPicker,
    FaqModal,
  },
})
export default class ReviewHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public sort_by: string = 'created_at'

  public sort_desc: boolean = false

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public invoices: Invoice[] = []

  public fieldFilters: any = {}

  public query: string[] = ['is:mine']

  public fields: Array<any> = []

  public statusList: any = []

  public net_total_pending: number = 0

  public selected: string[] = []

  public invoice_index: number = 0

  public invoice_nav_index: number = 0

  public singleChange: boolean = true

  public temp_invoice: Invoice = new Invoice()

  public temp_note: string = ''

  public isAllSelected = false

  public invoice_preview_mode: any = 'dynamic'

  public view_mode = 'columns'

  public show_included_items_only = false

  public query_settings: any = {
    custom_fields: [
      {
        name: 'status:pending',
        value: 'is:pending',
      },
      {
        name: 'is:mine',
        value: 'is:mine',
      },
    ],
  }

  public statusColor: any = {
    rejected: 'danger',
    pending: 'warning',
    approved: 'success',
  }

  public view_invoice_in_default_view = false

  @Watch('isAllSelected')
  public onSelectedChange(val: any) {
    if (!val) {
      this.resetSelection()
    } else {
      // @ts-ignore
      this.selected = this.invoices.map(i => i.id)
    }
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get totalPending() {
    return this.invoices.reduce((acc: number, obj: any) => (acc += obj.net_subtotal), 0)
  }

  public get hasUnsavedUpdated() {
    return this.invoices.some((inv: Invoice) => inv.is_dirty)
  }

  /**
   * Check if the selected invoices have empty notes.
   */
  public get invoicesHaveEmptyNotes() {
    let selectedInvoices = this.invoices.filter((invoice: Invoice) =>
      this.selected.includes(invoice.id!))
    return selectedInvoices.some(inv => inv.review_notes === null || !inv.review_notes?.length)
  }

  public get indeterminate(): boolean {
    return this.selected.length > 0 && this.selected.length < this.invoices.length
  }

  public editMediaPlan(id: string) {
    this.$router.push(`/app/sales/media_plan/${id}?ref=ReviewInvoices`)
  }

  public editInvoice(id: string) {
    this.$router.push(`/app/invoice/${id}?ref=ReviewInvoices`)
  }

  public toggleView() {
    if (this.invoice_preview_mode === 'invoice') this.invoice_preview_mode = 'schedule'
    else this.invoice_preview_mode = 'invoice'
  }

  public invoice_plan_target: any = []

  public getMediaPlanOptions(invoice_index: number) {
    if (
      !this.invoice_plan_target[invoice_index]
      || !this.invoices[invoice_index].media_plans.some(
        p => p.id === this.invoice_plan_target[invoice_index],
      )
    ) {
      this.invoice_plan_target[invoice_index] = this.invoices[invoice_index].media_plans[0].id
    }
    return this.invoices[invoice_index].media_plans.map(
      (plan: MediaPlan) => new SelectOption(`#${plan.number} - ${plan.name}`, plan.id),
    )
  }

  public invoice_status_check: any = {}

  public getStatusCheck(target: string) {
    if (!this.invoice_status_check[target]) {
      this.invoice_status_check[target] = {
        result: [],
        errors: [],
        warnings: [],
      }
    }

    return this.invoice_status_check[target]
  }

  public getIncludedItems(invoice: Invoice): string[] {
    if (!invoice.id) return []

    let included_line_item_ids: string[] = []
    invoice.items.forEach(i =>
      i.reconciliations.forEach(r => {
        included_line_item_ids.push(r.media_plan_item_id)
      }))

    return [...new Set(included_line_item_ids)]
  }

  public getElegibleItems(invoice: Invoice) {
    let elegible_line_items = invoice.media_plans.reduce(
      (acc: any, plan: any) =>
        acc.concat(
          plan.line_items
            .filter((i: MediaPlanItem) =>
              isCampagingInDateRange(
                invoice.billing_period.start_at,
                invoice.billing_period.end_at,
                i.start_at,
                i.end_at,
              ))
            .map((i: any) => {
              i = MediaPlanItem.toObject(i)
              i.type = plan.type
              i.agency_commission = plan.agency_commission
              return i
            }),
        ),
      [],
    )

    return elegible_line_items
  }

  public getElegibleButNotIncluded(invoice: Invoice) {
    // TODO FIX, not working
    let elegible_line_items = this.getElegibleItems(invoice)
    let included_line_item_ids = this.getIncludedItems(invoice)

    return elegible_line_items
      .filter((i: MediaPlanItem) => i.id && !included_line_item_ids.includes(i.id))
      .map((i: MediaPlanItem) => i.id)
  }

  public getStatusRating(invoice: Invoice): number {
    if (!invoice.id) return 0
    let result = []
    let errors: string[] = []
    let warnings: string[] = []

    let score = 5

    if (invoice.media_plans.length === 0) {
      result.push({
        status: 'warning',
        message: 'We were not able to find any media plan associated with this invoice.',
      })
      score -= 2
      return score
    }

    let included_line_item_ids: string[] = []
    invoice.items.forEach(i =>
      i.reconciliations.forEach(r => {
        included_line_item_ids.push(r.media_plan_item_id)
      }))

    included_line_item_ids = [...new Set(included_line_item_ids)]

    let elegible_line_items = invoice.media_plans.reduce(
      (acc: any, plan: any) =>
        acc.concat(
          plan.line_items
            .filter((i: MediaPlanItem) =>
              isCampagingInDateRange(
                invoice.billing_period.start_at,
                invoice.billing_period.end_at,
                i.start_at,
                i.end_at,
              ))
            .map((i: any) => {
              i = MediaPlanItem.toObject(i)
              i.type = plan.type
              i.agency_commission = plan.agency_commission
              return i
            }),
        ),
      [],
    )

    // Check if invoice gross and net match total Media Plan Gross (1pt)
    let total_media_plan_gross = elegible_line_items.reduce(
      (acc: number, obj: any) => (acc += obj.gross_cost),
      0,
    )
    let gross_diff = Math.abs(total_media_plan_gross - invoice.gross_subtotal)
    let total_media_plan_net = elegible_line_items.reduce(
      (acc: number, obj: MediaPlanItem) =>
        (acc += obj.metadata.order_type === 'trade' ? 0 : obj.net_cost),
      0,
    )
    let net_diff = Math.abs(total_media_plan_net - invoice.net_subtotal)

    if (net_diff <= 1 && gross_diff <= 1) {
      result.push({
        status: 'success',
        message: 'Invoice Net matches Total Media Plan Net',
      })
    } else {
      let is_flight_over = elegible_line_items.every((i: MediaPlanItem) =>
        moment(invoice.billing_period.end_at).isSameOrAfter(i.end_at))
      let open_ended = elegible_line_items.some((i: MediaPlanItem) => i.open_ended)
      let total_target = ''
      let total_diff = ''
      let fields = []
      if (net_diff >= 1 && gross_diff >= 1) {
        total_target = 'Gross & Net'
        // @ts-ignore
        total_diff = `Gross: ${this.$options.filters.currency(
          gross_diff,
          // @ts-ignore
        )} | Net: ${this.$options.filters.currency(net_diff)}`
        fields.push('gross_total')
        fields.push('net_total')
      } else if (net_diff >= 1) {
        total_target = 'Net'
        // @ts-ignore
        total_diff = `${this.$options.filters.currency(net_diff)}`
        fields.push('net_total')
      } else {
        total_target = 'Gross'
        // @ts-ignore
        total_diff = `${this.$options.filters.currency(gross_diff)}`
        fields.push('gross_total')
      }

      if (open_ended) {
        score -= 0.5
        result.push({
          status: 'warning',
          // @ts-ignore
          message: `Invoice ${total_target} does not match Media Plan, but it has open ended Line Items, discrepancy ${total_diff} (-0,5pts)`,
        })
        warnings.push(...fields)
      } else if (!is_flight_over) {
        score -= 0.5
        result.push({
          status: 'warning',
          // @ts-ignore
          message: `Invoice ${total_target} does not match Media Plan, but flight dates are greater than the invoice period, discrepancy ${total_diff} (-0,5pts)`,
        })
        warnings.push(...fields)
      } else {
        score -= 1
        result.push({
          status: 'danger',
          // @ts-ignore
          message: `Invoice ${total_target} does not match Media Plan and flight dates are over, discrepancy ${total_diff} (-1pts)`,
        })
        errors.push(...fields)
      }
    }

    // Check if media plan gross & net rate are the same
    if (invoice.isLinear) {
      let has_equal_rates = invoice.items.some((i: InvoiceItem) =>
        i.reconciliations.some((r: Reconciliation) => {
          let line_item = elegible_line_items.find(
            (li: MediaPlanItem) => li.id == r.media_plan_item_id,
          )
          return line_item && line_item.gross_rate == line_item.net_rate && line_item.gross_rate > 0
        }))

      if (has_equal_rates) {
        score -= 2
        result.push({
          status: 'danger',
          // @ts-ignore
          message:
            'Linear invoice with no agency commissions (Gross & Net Rates are the same) (-2pts)',
        })
        errors.push('gross_rate', 'net_rate')
      }
    }

    // Check if Invoice includes all items for the invoice date range (1pt)
    if (elegible_line_items.length == included_line_item_ids.length) {
      result.push({
        status: 'success',
        message: 'Invoice includes all items for the invoice date range',
      })
    } else {
      const missing_line_items = elegible_line_items.filter(
        (i: MediaPlanItem) => i.id && !included_line_item_ids.includes(i.id),
      )

      let missing_percentage = missing_line_items.length / elegible_line_items.length
      if (missing_percentage < 0.7) {
        score -= 1
        result.push({
          status: 'danger',
          message: `Invoice does not include all items for the invoice date range, missing ${missing_line_items.length} elegible item(s) (-1pt)`,
        })
      } else {
        score -= 0.5
        result.push({
          status: 'warning',
          message: `Invoice does not include all items for the invoice date range, missing ${missing_line_items.length} elegible item(s) (-1pt)`,
        })
      }
    }

    // Check if invoice matches actual flight dates (1pt)
    let valid_dates = invoice.items.every((i: InvoiceItem) => {
      if (invoice.isLinear) {
        return i.reconciliations.every((r: Reconciliation) => {
          let line_item = elegible_line_items.find(
            (li: MediaPlanItem) => li.id == r.media_plan_item_id,
          )
          if (line_item) {
            if (line_item.metadata.order_type === 'trade') {
              const invoice_date = moment(i.metadata.run_date)
              const line_date = moment(
                `${line_item.schedule_start_at} ${line_item.metadata.flight_time.start_at}`,
              )

              return (
                invoice_date.isSame(line_date)
                || (invoice_date.format('YYYY-MM-DD') === line_date.format('YYYY-MM-DD')
                  && invoice_date.format('HH:mm:ss') === '23:00:00')
              )
            }
            return moment(i.metadata.run_date).isSame(
              `${line_item.schedule_start_at} ${line_item.metadata.flight_time.start_at}`,
            )
          }
          return false
        })
      }

      let multiple_items = [...new Set(i.reconciliations.map(r => r.media_plan_item_id))].length > 1
      return i.reconciliations.every((r: Reconciliation) => {
        let line_item = elegible_line_items.find(
          (li: MediaPlanItem) => li.id == r.media_plan_item_id,
        )
        if (line_item) {
          if (multiple_items) {
            return (
              moment(i.start_at).isSameOrBefore(line_item.start_at)
              && moment(i.end_at).isSameOrAfter(line_item.end_at)
            )
          }

          return (
            moment(i.start_at).isSame(line_item.start_at)
            && moment(i.end_at).isSame(line_item.end_at)
          )
        }
        return false
      })
    })
    if (valid_dates) {
      result.push({
        status: 'success',
        message: invoice.isLinear
          ? 'Invoice Item dates match the Media Plan Item Schedule dates'
          : 'Invoice Item dates match the Media Plan Item dates',
      })
    } else {
      score -= 1
      result.push({
        status: 'danger',
        message: invoice.isLinear
          ? 'Invoice Item dates do not match the Media Plan Item Schedule dates (-1pt)'
          : 'Invoice Item dates do not match the Media Plan Item dates (-1pt)',
      })
    }

    // Check if invoice metadata matches the media plan metadata (1pt)
    let metadata_issues: string[] = []
    // Check Media PLan Data
    invoice.media_plans.forEach(media_plan => {
      if (invoice.isLinear) {
        if (
          invoice.metadata.agency.id !== media_plan.metadata.agency.id
          || !invoice.metadata.agency.id
        ) {
          metadata_issues.push('IDB Code')
          errors.push('idb_code')
        }
        if (
          invoice.metadata.agency.name !== media_plan.metadata.agency.name
          || !invoice.metadata.agency.name
        ) {
          metadata_issues.push('Agency Name')
          errors.push('agency_name')
        }
        if (
          invoice.metadata.agency.address_line_1 != media_plan.metadata.agency.address_line_1
          || !invoice.metadata.agency.address_line_1
        ) {
          metadata_issues.push('Address Line 1')
          errors.push('address_line_1')
        }
        if (
          invoice.metadata.agency.address_line_2 != media_plan.metadata.agency.address_line_2
          || !invoice.metadata.agency.address_line_2
        ) {
          metadata_issues.push('Address Line 2')
          errors.push('address_line_2')
        }
        if (
          invoice.metadata.agency.address_line_3 != media_plan.metadata.agency.address_line_3
          && (!!invoice.metadata.agency.address_line_3 || !!media_plan.metadata.agency.address_line_3)
        ) {
          metadata_issues.push('Address Line 3')
          errors.push('address_line_3')
        }
        if (
          invoice.metadata.agency.address_line_4 != media_plan.metadata.agency.address_line_4
          && (!!invoice.metadata.agency.address_line_4 || !!media_plan.metadata.agency.address_line_4)
        ) {
          metadata_issues.push('Address Line 4')
          errors.push('address_line_4')
        }

        // Station Data
        if (
          invoice.metadata.station.call_letters !== media_plan.metadata.station.call_letters
          || !invoice.metadata.station.call_letters
        ) {
          metadata_issues.push('Station Call Letters')
          errors.push('station_call_letters')
        }
        // if (
        //   invoice.metadata.station.address_line_1 !== media_plan.metadata.station.address_line_1
        //   || !invoice.metadata.station.address_line_1
        // ) {
        //   metadata_issues.push('Station Address Line 1')
        //   errors.push('station_address_line_1')
        // }
        // if (
        //   invoice.metadata.station.address_line_2 !== media_plan.metadata.station.address_line_2
        //   || !invoice.metadata.station.address_line_2
        // ) {
        //   metadata_issues.push('Station Address Line 2')
        //   errors.push('station_address_line_2')
        // }
        // if (invoice.metadata.station.address_line_3 !== media_plan.metadata.station.address_line_3) {
        //   metadata_issues.push('Station Address Line 3')
        //   errors.push('station_address_line_3')
        // }
        // if (invoice.metadata.station.address_line_4 !== media_plan.metadata.station.address_line_4) {
        //   metadata_issues.push('Station Address Line 4')
        //   errors.push('station_address_line_4')
        // }

        if (invoice.metadata.header.representative !== media_plan.metadata.header.representative) {
          metadata_issues.push('Representative')
          errors.push('representative')
        }
        if (
          invoice.metadata.header.advertiser_name !== media_plan.metadata.header.advertiser_name
        ) {
          metadata_issues.push('Advertiser Name')
          errors.push('advertiser_name')
        }
        if (invoice.metadata.header.product_name !== media_plan.metadata.header.product_name) {
          metadata_issues.push('Product Name')
          errors.push('product_name')
        }
        if (
          invoice.metadata.header.agency_estimate_code
          !== media_plan.metadata.header.agency_estimate_code
        ) {
          metadata_issues.push('Agency Estimate Code')
          errors.push('agency_estimate_code')
        }
        if (invoice.metadata.header.start_date !== media_plan.metadata.header.start_date) {
          metadata_issues.push('Start Date')
          errors.push('start_date')
        }
        if (invoice.metadata.header.end_date !== media_plan.metadata.header.end_date) {
          metadata_issues.push('End Date')
          errors.push('end_date')
        }
        if (
          invoice.metadata.header.station_order_number
            !== media_plan.metadata.header.station_order_number
          || !invoice.metadata.header.station_order_number
        ) {
          metadata_issues.push('Station Order Number')
          errors.push('station_order_number')
        }
        if (
          invoice.metadata.header.station_trade_order_number
            !== media_plan.metadata.header.station_trade_order_number
          || (!invoice.metadata.header.station_trade_order_number && invoice.has_trade_items)
        ) {
          metadata_issues.push('Station Trade Order Number')
          errors.push('station_trade_order_number')
        }
        if (
          invoice.metadata.header.agency_advertiser_code
          !== media_plan.metadata.header.agency_advertiser_code
        ) {
          metadata_issues.push('Agency Advertiser Code')
          errors.push('agency_advertiser_code')
        }
        if (
          invoice.metadata.header.agency_product_code
          !== media_plan.metadata.header.agency_product_code
        ) {
          metadata_issues.push('Agency Product Code')
          errors.push('agency_product_code')
        }
        return
      }

      if (
        invoice.metadata.client_data.name != media_plan.metadata.client_data.name
        && (invoice.metadata.client_data.name || media_plan.metadata.client_data.name)
      ) {
        metadata_issues.push('Client Name')
        errors.push('client_name')
      }
      if (
        invoice.metadata.client_data.order != media_plan.metadata.client_data.order
        && (invoice.metadata.client_data.order || media_plan.metadata.client_data.order)
      ) {
        metadata_issues.push('Order')
        errors.push('order')
      }
      if (
        invoice.metadata.client_data.product != media_plan.metadata.client_data.product
        && (invoice.metadata.client_data.product || media_plan.metadata.client_data.product)
      ) {
        metadata_issues.push('Product')
        errors.push('product')
      }
      if (
        invoice.metadata.client_data.estimate != media_plan.metadata.client_data.estimate
        && (invoice.metadata.client_data.estimate || media_plan.metadata.client_data.estimate)
      ) {
        metadata_issues.push('Estimate')
        errors.push('estimate')
      }
      if (
        invoice.metadata.client_data.product_estimate
          !== media_plan.metadata.client_data.product_estimate
        && (invoice.metadata.client_data.product_estimate
          || media_plan.metadata.client_data.product_estimate)
      ) {
        metadata_issues.push('Product Estimate')
        errors.push('product_estimate')
      }
    })
    // Check Line Item data
    if (invoice.isLinear) {
      // Check invoice Flight Dates
      let start = moment(invoice.metadata.header.start_date, 'YYMMDD')
      let end = moment(invoice.metadata.header.end_date, 'YYMMDD')
      if (!start.isValid() || !end.isValid()) {
        score -= 2
        result.push({
          status: 'danger',
          message: 'Invalid Start or End Date (-2pt)',
        })
      } else if (start.isSameOrAfter(end)) {
        score -= 2
        result.push({
          status: 'danger',
          message: 'Invoice Start Date is greater than End Date (-2pt)',
        })
      }
    }

    invoice.items.forEach((i: InvoiceItem) => {
      let has_dynamic_rate = i.reconciliations.some((r: Reconciliation) => {
        let line_item = elegible_line_items.find(
          (li: MediaPlanItem) => li.id == r.media_plan_item_id,
        )
        return line_item && line_item.metadata.dynamic_rate_id
      })
      let has_open_ended = i.reconciliations.some((r: Reconciliation) => {
        let line_item = elegible_line_items.find(
          (li: MediaPlanItem) => li.id == r.media_plan_item_id,
        )
        return line_item && line_item.open_ended
      })
      let has_multiple_plans = i.reconciliations.some(
        (r: Reconciliation) => r.media_plan_item_id !== i.reconciliations[0].media_plan_item_id,
      )
      i.reconciliations.forEach((r: Reconciliation) => {
        let line_item = elegible_line_items.find(
          (li: MediaPlanItem) => li.id == r.media_plan_item_id,
        )
        if (line_item) {
          if (invoice.isLinear) {
            if (line_item.metadata.order_type !== i.metadata.order_type) {
              metadata_issues.push('Order Type')
              errors.push(`${i.id}_order_type`)
            }
            if (line_item.metadata.program_name !== i.metadata.program_name) {
              metadata_issues.push('Program Name')
              errors.push(`${i.id}_program_name`)
            }
          }

          if (!line_item.dynamic_rate_id && !has_multiple_plans) {
            if (Math.abs(line_item.gross_rate - i.gross_rate) > 1) {
              errors.push(`${i.id}_gross_rate`)
            }
            if (Math.abs(line_item.net_rate - i.net_rate) > 1) {
              errors.push(`${i.id}_net_rate`)
            }
          }

          if (!line_item.dynamic_rate_id && !has_open_ended) {
            if (Math.abs(line_item.gross_cost - i.gross_total) > 1) {
              errors.push(`${i.id}_gross_total`)
            }

            if (Math.abs(line_item.net_cost - i.net_total) > 1) {
              errors.push(`${i.id}_net_total`)
            }
          }
        }
      })
    })

    metadata_issues = [...new Set(metadata_issues)]
    if (metadata_issues.length == 0) {
      result.push({
        status: 'success',
        message: 'Invoice Client Data matches the Media Plans Client Data',
      })
    } else {
      score -= 1
      result.push({
        status: 'danger',
        message: `Invoice Client Data does not match the Media Plans Client Data, please review ${metadata_issues.join(
          ', ',
        )} (-1pt)`,
      })
    }

    // Check if invoice client matches the media plan client (1pt)
    const match_client = invoice.media_plans.every(
      (plan: MediaPlan) => plan.billing_client_id === invoice.client_id,
    )
    if (match_client) {
      result.push({
        status: 'success',
        message: 'Invoice Client matches the Media Plan Client',
      })
    } else {
      score -= 1
      result.push({
        status: 'warning',
        message: 'Invoice Client does not match some of the Media Plan Clients (-1pt)',
      })
    }

    this.invoice_status_check[invoice.id] = { result, errors, warnings }

    return score < 0 ? 0 : score
  }

  public getStatusColor(invoice: Invoice): string {
    const rate = this.getStatusRating(invoice)
    if (rate <= 2.5) {
      return 'danger'
    }
    if (rate <= 4) {
      return 'warning'
    }
    if (rate <= 4.5) {
      return 'info'
    }

    return 'success'
  }

  public async recalculateInvoice(row: Invoice, index: number) {
    this.invoice_index = index

    let copied_invoice: Invoice = _clone(this.invoices[index])
    copied_invoice = await copied_invoice.rebuildInvoice('single', copied_invoice.group_mode)
    copied_invoice.id = _clone(this.invoices[index].id)
    copied_invoice.number = _clone(this.invoices[index].number)
    copied_invoice.name = _clone(this.invoices[index].name)

    if (copied_invoice._hash !== this.invoices[index]._hash) {
      this.temp_invoice = copied_invoice
      this.$root.$emit('bv::show::modal', 'invoice-sync')
    } else {
      WebMessage.info(
        'Looks like nothing has changed. Please review the Media Plans and try again.',
      )
    }
  }

  public updateInvoice(send: boolean = false) {
    this.loading = true

    if (
      this.invoices[this.invoice_index].total != this.temp_invoice.total
      && !this.user.can('invoice', 'edit')
    ) {
      WebMessage.error(
        'This action will change the invoice total. You are not authorized to perform this action, please contact AdOps or Finance.',
      )
      return
    }
    if (
      this.invoices[this.invoice_index].total != this.temp_invoice.total
      && this.temp_invoice.isBooksClosed
    ) {
      WebMessage.confirm(
        'Are you sure you want to change the invoice total amount? This action will generate a Credit Memo automatically.',
        'Books Closed!',
        {
          okTitle: 'Yes',
          cancelText: 'No',
        },
      ).then((result: any) => {
        if (result) {
          this.executeInvoiceUpdate(send)
          return
        }
        this.loading = false
      })

      return
    }

    this.executeInvoiceUpdate(send)
  }

  public executeInvoiceUpdate(send: boolean = false) {
    this.temp_invoice.save().then(() => {
      this.invoices[this.invoice_index] = this.temp_invoice

      setTimeout(() => {
        if (send && this.temp_invoice.id) {
          Invoice.requestApproval([this.temp_invoice.id]).then(() => {
            this.dataTable.refresh()
          })
        }
        this.$bvModal.hide('invoice-sync')
        this.loading = false
        this.dataTable.refresh()
      }, 500)
    })
  }

  /**
   * Check if has unsaved records
   * Invoke on table data change(pagination, search, import), leave page
   */
  public checkDirtyRecords(
    callback: any = null,
    message: string = 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it first?',
  ) {
    // check if dirty
    if (this.hasUnsavedUpdated) {
      return WebMessage.confirm(message, 'Unsaved Items', {
        okTitle: 'Save',
        cancelTitle: 'Discard',
      }).then((save: boolean) => {
        if (save) {
          this.saveInvoiceChanges()?.then(() => {
            if (callback) {
              callback()
            }
          })
        } else {
          return callback ? callback() : Promise.resolve()
        }
      })
    }
    return callback ? callback() : Promise.resolve()
  }

  public beforeRouteLeave(to: object, from: object, next: any) {
    if (!this.hasUnsavedUpdated) {
      next()
    } else {
      this.checkDirtyRecords(() => {
        next()
      })
    }
  }

  public mounted() {
    this.fields = invoice_review_table_fields
    this.loadFilters()

    this.$root.$on('bv::modal::hide', () => {
      this.invoice_index = 0
      this.resetInvoice()
    })
  }

  public resetInvoice() {
    this.temp_invoice = new Invoice()
  }

  // Clear all selection
  public resetSelection() {
    this.$set(this, 'selected', [])
  }

  public viewDetails(invoice: Invoice) {
    this.viewItem(invoice.id!)
    if (invoice.type === 'media_ocean' || invoice.type === 'strata') {
      this.invoice_preview_mode = 'schedule'
    } else {
      this.invoice_preview_mode = 'invoice'
    }
    this.openModal('invoice-print')
  }

  /**
   * Inject temp_invoice.review_notes to temp_note
   *
   * Check if the given invoice has review_notes
   *
   * If no param, it will check the temp_invoice.review_notes
   *
   * This is useful for the add-note modal
   */
  public setTempNote(selectedInvoice: Invoice | null = null) {
    let local_invoice = selectedInvoice || this.temp_invoice
    if (local_invoice.review_notes) {
      this.$set(this, 'temp_note', this.temp_invoice.review_notes)
    }
  }

  public getItem(id: string): Invoice {
    return this.invoices.find(inv => inv.id === id)!
  }

  /**
   * Returns the index of the given invoice.id
   *
   * Injects to temp_invoice the given invoice
   */
  public viewItem(id: string, nav_index = 0): number {
    this.temp_note = ''
    let index = this.invoices.findIndex(inv => inv.id === id)

    this.invoice_index = index
    this.invoice_nav_index = nav_index

    this.$set(this, 'temp_invoice', this.invoices[index])
    this.setTempNote(this.invoices[index])

    return index
  }

  /**
   * Add the temp_note to the current invoice
   *
   * The current invoice is defined by index
   *
   * this.invoice_index will contain the current invoice index
   *
   * If adding note to a single selected invoice it will trigger saveInvoiceChanges()
   */
  public addNoteToInvoice() {
    // probably add some notification about empty review note
    if (!this.temp_note.length) return

    if (this.singleChange) {
      this.invoices[this.invoice_index!].review_notes = this.temp_note
      this.invoices[this.invoice_index!].review_status = 'rejected'

      // add invoice to temp_invoice for the function saveInvoiceChanges conditions
      this.temp_invoice = this.invoices[this.invoice_index!]
      this.saveInvoiceChanges()
      return
    }
    this.invoices[this.invoice_index!].review_notes = this.temp_note
    this.invoice_nav_index++
    if (this.invoice_nav_index > this.selected.length - 1) return
    const next_id = this.selected[this.invoice_nav_index]
    this.viewItem(next_id, this.invoice_nav_index)
  }

  public invoicesRow(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return Invoice.loadPendingReviews({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.ready = true
      this.invoices = result.data
      this.statusList = result.statusList
      this.net_total_pending = result.net_total_pending
      this.temp_invoice = this.invoices[0]
      this.isAllSelected = true
      return result.data
    })
  }

  /**
   * Isolated function for the modal
   * The perpose of this is to not polute the other functions with timeouts for the modal
   */
  public openModal(modal_id: string = 'add-note', time = 100): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', modal_id)
        resolve(true)
      }, time)
    })
  }

  /**
   * Close any modal by id
   *
   * If no id given it will use add-note modal as id
   */
  public closeModal(modal_id: string = 'add-note') {
    this.$root.$emit('bv::hide::modal', modal_id)
  }

  /**
   * Change single invoice status
   *
   * If status:rejected it will toggle the note modal
   */
  public changeSingleInvoice(invoice: Invoice, status: string) {
    this.singleChange = true
    let index = this.viewItem(invoice.id!)
    // this.invoices[index].review_status = status
    // if rejected dont change local invoice status
    if (status === 'rejected') {
      this.openModal()
      this.temp_invoice = _clone(this.invoices[index])
      this.temp_invoice.review_status = status
    } else {
      // approved status
      this.invoices[index].review_status = status
      this.saveInvoiceChanges()
    }
  }

  /**
   * Change all selected invoices status
   *
   * If status:rejected it will toggle the add-note modal
   */
  public bulkChangeStatus(status: string = 'approved') {
    this.singleChange = false

    let selectedInvoices = this.invoices.filter((invoice: Invoice) => {
      if (this.selected.includes(invoice.id!)) {
        invoice.review_status = status
        return invoice
      }
      return false
    })

    if (status === 'rejected') {
      // get the first invoice  where the notes are empty
      let first = selectedInvoices.find(inv => !inv.review_notes)
      // get the first found invoice and use its id to find the navigation index position in this.selected
      this.invoice_nav_index = this.selected.findIndex(sel => sel === first.id)
      // @ts-ignore
      this.viewItem(first.id, this.invoice_nav_index)
      this.setTempNote()
      this.openModal()
    }
  }

  public saveInvoiceChanges() {
    this.loading = true
    let selectedInvoices: any = []

    if (this.singleChange) {
      selectedInvoices = [this.temp_invoice]
    } else {
      selectedInvoices = this.invoices.filter((invoice: Invoice) =>
        this.selected.includes(invoice.id!))
    }
    if (!selectedInvoices.length) {
      this.loading = false
      return
    }

    return Invoice.review(selectedInvoices).then(r => {
      this.loading = false
      this.resetInvoice()
      this.closeModal()
      this.singleChange = false

      this.selected = []
      this.invoice_index = null
      setTimeout(() => {
        this.dataTable.refresh()
      }, 500)

      return r
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('invoices-review').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'invoices-review',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'invoices-review',
      data: null,
    })
  }
}
